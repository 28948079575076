<template>
  <div class="content">
    <div class="banner">
      <div class="title">二手商用车鉴定评估报告</div>
      <div class="min-t">查询服务</div>
      <div class="s-box">
        <div class="search">
          <img @click="search" src="../assets/search/s-icon.png" alt />
          <input
            @keyup.enter="search"
            type="text"
            v-model.trim="value"
            placeholder="请输入报告编号（位于各文件标题左上角）"
          />
          <div @click="search" class="search-btn">查询</div>
        </div>
        <div class="num" @click="myCopy">
          <div>
            示例报告
            <span id="copyInner">CADARW20230531105145924628400046</span>
          </div>
          <img src="../assets/search/copy.png" alt />
        </div>
      </div>
    </div>
    <div class="footer">
      <ul>
        <li class="it">
          <img src="../assets/search/params.png" alt />
          <div class="txt">
            <div>车型参数</div>
            <div>登记车型的基本信息和重要配置</div>
          </div>
        </li>
        <li class="it">
          <img src="../assets/search/info.png" alt />
          <div class="txt">
            <div>随车资料</div>
            <div>登记车辆的手续证件资料、相关物品和维修、保养、事故记录等</div>
          </div>
        </li>
        <li class="it">
          <img src="../assets/search/search.png" alt />
          <div class="txt">
            <div>鉴定状况</div>
            <div>里程状况、年限状况、技术状况等具体车况的鉴定</div>
          </div>
        </li>
        <li class="it">
          <img src="../assets/search/img.png" alt />
          <div class="txt">
            <div>车辆照片</div>
            <div>对车辆外观、驾驶室、铭牌钢印轮胎、上装和证件等完整拍照记录</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { debounce } from "@/utils/common.js";
export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      value: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    if (!this.isMobile()) {
      window.open("http://cnums.chinaucv.com/verification", "_self");
      // window.open("http://192.168.3.91:8080/verification", "_self");
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    search: debounce(function () {
      if (!this.value) {
        Toast("请输入要查询的报告编号");
        return;
      }
      if (this.value.length != 32) {
        Toast("请核对报告编号是否正确");
        return;
      }
      this.$store.commit("set_reportNum", this.value);
      this.$router.push(`/message`);
    }, 500),
    myCopy() {
      const range = document.createRange();
      range.selectNode(document.getElementById("copyInner"));
      const selection = window.getSelection();
      if (selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      if (document.execCommand("copy")) {
        Toast("复制成功");
      }
    },
  },
};
</script>
<style scoped>
.content {
  background: #f8f8f8;
  height: 100vh;
}
.banner {
  width: 100%;
  background-image: url("../assets/search/bg.png");
  background-size: cover;
  height: 233px;
}
.title {
  padding-top: 42px;
  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
}
.min-t {
  font-size: 18px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
}
.s-box {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  margin-top: 12px;
}
.search {
  width: 335px;
  height: 35px;
  background: #ffffff;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.search > img {
  width: 13px;
  height: 20px;
  margin: 0 10px;
}
.search > input {
  flex: 1;
  background: none;
  font-size: 13px;
  height: 100%;
}
.num {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 12px;
}
.num > img {
  width: 11px;
  height: 18px;
  margin-left: 3px;
}
.footer {
  width: 335px;
  height: 380px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: -46px;
}
ul {
  box-sizing: border-box;
  padding: 20px;
}
.it {
  display: flex;
  border-top: 1px solid #dbdbdb;
  padding: 15px 0;
}
.it > img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

ul > .it:nth-of-type(1) {
  border: none;
}
.txt > div:nth-of-type(1) {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #666666;
}
.txt > div:nth-of-type(2) {
  width: 230px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 400;
  margin-top: 10px;
  color: #333333;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 18px;
}
.search-btn {
  width: 48px;
  height: 35px;
  background: #0502cf;
  border-radius: 0px 3px 3px 0px;
  text-align: center;
  line-height: 35px;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
</style>